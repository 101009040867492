import React, { useEffect, useState } from "react";
import DataService from "../../../data-service";
import { InputEditProject } from "./InputEditProject";
import { InputSelectorEditTypology } from "./InputSelectorEditTypology";
import { getDecimalsPercentage, originalNumber } from '../consts/changePercentageM2';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { CheckEditProject } from "./CheckEditProject";
import { ButtonLoading } from "../../../UI/ButtonLoading";

export const ModalEditProject = ({ project, request, setRequest, getProjectsInfo }) => {
  const projectArea = project.area || 0;
  const service = new DataService()

  const tipologiesId = {
    A: 1,
    AA: 2,
    B: 3,
    BB: 4,
    C: 5,
    CC: 6,
    D: 7,
    DD: 8
  }

  const [superApus, setSuperApus] = useState([])
  const [superApusRooms, setSuperApusRooms] = useState([])
  const [roomPicked, setRoomPicked] = useState("")
  const [apusSelected, setApusSelected] = useState([])

  const getApus = () => {
    axios.get(`${process.env.REACT_APP_API_URL}get-all-superApus`)
      .then((res) => {
        setSuperApus(res.data)
        let rooms = res.data?.allSuperApus?.map(apu => apu.espacio)
        setSuperApusRooms([...new Set(rooms)])
      })
  }

  const [editProject, setEditProject] = useState({
    id: project.id,
    nombre: project.nombre,
    constructora: project.constructora,
    tipo: project.tipo,
    area: project.area || 0,
    b_cant: project.b_cant,
    h_cant: project.h_cant,
    cocina: originalNumber(project.cocina, projectArea),
    tipologia: tipologiesId[project.tipologia],
    sala_comedor: originalNumber(project.sala_comedor, projectArea),
    lavanderia: originalNumber(project.lavanderia, projectArea),
    hab_principal: originalNumber(project.hab_principal, projectArea),
    baño_principal: originalNumber(project.baño_principal, projectArea),
    baño1: originalNumber(project.baño1, projectArea),
    hab1: originalNumber(project.hab1, projectArea),
    hab2: originalNumber(project.hab2, projectArea),
    hall: originalNumber(project.hall, projectArea),
    estudio: originalNumber(project.estudio, projectArea),
  });
  const [btnLoading, setBtnLoading] = useState(false);


  const projectToUpload = {
    ...editProject,
    cocina: getDecimalsPercentage(editProject.cocina, projectArea),
    sala_comedor: getDecimalsPercentage(editProject.sala_comedor, projectArea),
    lavanderia: getDecimalsPercentage(editProject.lavanderia, projectArea),
    hab_principal: getDecimalsPercentage(editProject.hab_principal, projectArea),
    baño_principal: getDecimalsPercentage(editProject.baño_principal, projectArea),
    baño1: getDecimalsPercentage(editProject.baño1, projectArea),
    hab1: getDecimalsPercentage(editProject.hab1, projectArea),
    hab2: getDecimalsPercentage(editProject.hab2, projectArea),
    hall: getDecimalsPercentage(editProject.hall, projectArea),
    estudio: getDecimalsPercentage(editProject.estudio, projectArea)
  }

  const handleEdit = () => {
    setBtnLoading(true);
    service
      .editProject(project.id, projectToUpload)
      .then(() => {
        console.log("Project edited");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBtnLoading(false);
        alert("Proyecto editado correctamente");
        setRequest(!request);
      });
  };
  const [projectHasApus, setProjectHasApus] = useState([]);
  const [savingData, setSavingData] = useState(false)
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}get-project-superapus/${project.id}`)
      .then(response => setProjectHasApus(response.data.superApusOfProject))
      .catch(error => console.log(error))
  }, [request, savingData]);


  const handleSuperApuRoom = (e) => {
    setRoomPicked(e.target.value)
  };

  const updateProjectsInfo = () => {
    getProjectsInfo();
  }

  return (
    <>
      {btnLoading ? <ButtonLoading /> :
        <button
          className="btn btn-primary m-1 hoverBtn"
          data-bs-toggle="modal"
          data-bs-target={`#modal${project.id}`}
          onClick={getApus}>
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>}

      <div
        className="modal fade"
        id={`modal${project.id}`}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Actualiza el valor que desees</h4>
            </div>
            <div className="modal-body">
              <form className="modal__form-grid bg-primary text-center">


                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Nombre </label>

                  <InputEditProject
                    value={editProject.nombre}
                    name={"nombre"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                    readOnly={true}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Constructora </label>

                  <InputEditProject
                    value={editProject.constructora}
                    name={"constructora"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                    readOnly={true}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Tipo </label>

                  <InputEditProject
                    value={editProject.tipo}
                    name={"tipo"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Área </label>

                  <InputEditProject
                    value={editProject.area}
                    name={"area"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Cantidad baños </label>

                  <InputEditProject
                    value={editProject.b_cant}
                    name={"b_cant"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Cantidad habitaciones </label>

                  <InputEditProject
                    value={editProject.h_cant}
                    name={"h_cant"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Cocina </label>

                  <InputEditProject
                    value={editProject.cocina}
                    name={"cocina"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />


                </div>


                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Tipología </label>

                  <InputSelectorEditTypology
                    value={editProject.tipologia}
                    name={"tipologia"}
                    editProject={editProject}
                    setEditProject={setEditProject} />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Aseo </label>

                  <InputEditProject
                    value={editProject.lavanderia}
                    name={"lavanderia"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Baño principal </label>

                  <InputEditProject
                    value={editProject.baño_principal}
                    name={"baño_principal"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Habitación principal </label>

                  <InputEditProject
                    value={editProject.hab_principal}
                    name={"hab_principal"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Sala / Comedor </label>

                  <InputEditProject
                    value={editProject.sala_comedor}
                    name={"sala_comedor"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Baño secundario </label>

                  <InputEditProject
                    value={editProject.baño1}
                    name={"baño1"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Habitación secundaria </label>

                  <InputEditProject
                    value={editProject.hab1}
                    name={"hab1"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Habitación terciaria </label>

                  <InputEditProject
                    value={editProject.hab2}
                    name={"hab2"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Hall </label>

                  <InputEditProject
                    value={editProject.hall}
                    name={"hall"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center  p-2">
                  <label className="text-white"> Estudio </label>
                  <InputEditProject
                    value={editProject.estudio}
                    name={"estudio"}
                    editProject={editProject}
                    setEditProject={setEditProject}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center ">
                  <label className="text-white">Espacio</label>
                  <select className="form-select w-75" onChange={handleSuperApuRoom}>
                    <option defaultValue>Seleciona un espacio</option>
                    {superApusRooms?.map((room) => {
                      return (
                        <option key={room}>
                          {room}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </form>

            </div>
            <div className="modal-footer justify-content-center flex-row">
              <div className="d-flex border rounded w-100 gap-1">
                <div className=" bg-light p-2">
                  <h4 className="text-center fs-4">Apus ligados actualmente:</h4>
                  {
                    projectHasApus ?
                      projectHasApus.map(apu => {
                        return (
                          <p><b>{apu.apu_name}</b> que pertenece a: <i>{apu.espacio}</i> con {apu.lineal_meter}ml</p>
                        )
                      }) :
                      <p>No hay apus aún</p>
                  }
                </div>

                <div className="bg-secondary w-75 p-2">
                  <h4 className="text-center fs-4">Ligar Apu</h4>
                  {roomPicked.length > 0 && superApus?.allSuperApus?.map((superapu) => {
                    if (superapu.espacio === roomPicked && superapu.measure_unit === "ml") {
                      return (
                        <CheckEditProject project_id={project.id} apusSelected={apusSelected} setApusSelected={setApusSelected} superapu={superapu} roomPicked={roomPicked} key={superapu.superapu_id} updateProjectsInfo={updateProjectsInfo} savingData={savingData} setSavingData={setSavingData} />
                      )
                    } else{
                      return []
                    }
                  })}
                </div>
              </div>

              <div className="w-100 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary hoverBtn mx-4 w-25"
                  data-bs-dismiss="modal"
                  onClick={handleEdit}
                >
                  Editar
                </button>
                <button
                  type="button"
                  className="btn btn-secondary hoverBtn w-25"
                  data-bs-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
