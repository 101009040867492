import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom';
import DataService from '../../data-service';
import CreateCodeForm from './CreateCodeForm';
import AllCodesTable from './AllCodesTable';

const DisscountCodesView = () => {
    const [allCodes, setAllCodes] = useState([]);
    useEffect(() => {
        getAllCodes();
    }, []);

    const service = new DataService();

    const getAllCodes = () => {
        service.getAllDisscountCodes()
            .then(response => {
                console.log(response.codes);
                setAllCodes(response.codes);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const history = useNavigate();
    return (
        <>
            <div className="col-12 " >
                <button className="btn btn-lg btn-secondary d-flex align-self-start m-3 hoverBtn" onClick={() => history("/main-view")}>Volver</button>
                <div className='row justify-content-center pt-3'>
                    <div className='col-4 mt-4'>
                        <h2 style={{ color: "white", fontSize: "60px", textShadow: "#EBC991 3px 5px 5px", marginLeft: "10px" }}>
                            Codes Panel
                        </h2>
                    </div>
                </div>
            </div>
            <div className="col-12" >
                <div className='row'>
                    <AllCodesTable allCodes={allCodes} updateCodeList={getAllCodes} service={service}/>
                    <CreateCodeForm updateCodeList={getAllCodes} />
                </div>
            </div>
        </>
    )
}

export default DisscountCodesView