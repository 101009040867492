import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Spinner } from "../../UI/Spinner";
import { useNavigate } from "react-router-dom";
import DataService from "../../data-service";
import { WhatsappLeadsRow } from "./WhatsappLeadsRow";

export const WhatsappLeadsScreen = () => {
  const [whatsappLeads, setWhatsappLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const dataService = new DataService();

  useEffect(() => {
    setLoading(true);
    dataService
      .getWhatsappLeads()
      .then((data) => setWhatsappLeads(data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div
      className="col-12 d-flex flex-column justify-content-around align-items-center p-3"
      style={{ minHeight: "600px" }}
    >
      <div className="d-flex d-flex justify-content-around align-items-center">
        <FontAwesomeIcon icon={faWhatsapp} size={"3x"} />{" "}
        <h2
          style={{
            color: "white",
            fontSize: "60px",
            textShadow: "#EBC991 3px 5px 5px",
            marginLeft: "10px",
          }}
        >
          Whatsapp Leads
        </h2>
      </div>

      <button
        className="btn btn-lg btn-secondary d-flex align-self-start m-3 hoverBtn"
        onClick={() => history("/main-view")}
      >
        Volver
      </button>
      <div className="table-responsive w-100">
        <table className="table table-primary table-hover ">
          <thead>
            {/*  ENCABEZADOS */}
            <tr className="table-secondary">
              <th scope="col">ID</th>
              <th scope="col">Nombre</th>
              <th scope="col">Telefono</th>
              <th scope="col">Email</th>
              <th scope="col">Ubicación</th>
              <th scope="col">Interes</th>
              <th scope="col">Detalles</th>
            </tr>
          </thead>
          <tbody>
            {/*  RENDERIZA CUERPO DE LA TABLA CON LA DATA DE LEADS */}
            {whatsappLeads.map((lead, index) => {
              return <WhatsappLeadsRow lead={lead} key={lead.id} index={index + 1} />;
            })}
          </tbody>
        </table>
        {loading && <Spinner />}
      </div>
    </div>
  );
};

export default WhatsappLeadsScreen;
