import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DataService from "../../data-service";
import { Spinner } from "../../UI/Spinner";
import { LeadsRow } from "./LeadsRow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMugHot } from "@fortawesome/free-solid-svg-icons";

const AllLeadsView = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const dataService = new DataService();

  useEffect(() => {
    setLoading(true);
    dataService
      .getLeads()
      .then((data) => {
        const actualData = data
          .sort(function (a, b) {
            if (a.id > b.id) {
              return -1;
            }
            if (a.id < b.id) {
              return 1;
            }
            return 0;
          })
          .filter(lead => !lead.email.includes('prueba') && !lead.nombre.toLowerCase().includes('prueba'));
          console.log(actualData)
        setLeads(actualData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className="col-12 d-flex flex-column justify-content-around align-items-center p-3"
      style={{ minHeight: "600px" }}
    >
      <div className="d-flex d-flex justify-content-around align-items-center">
        <FontAwesomeIcon icon={faMugHot} size={"3x"} />
        <h2
          style={{
            color: "white",
            fontSize: "60px",
            textShadow: "#EBC991 3px 5px 5px",
            marginLeft: "10px"
          }}
        >
          Hot Leads
        </h2>
      </div>

      <button
        className="btn btn-lg btn-secondary d-flex align-self-start m-3 hoverBtn"
        onClick={() => history("/main-view")}
      >
        Volver
      </button>
      <div className="table-responsive w-100">
        <table className="table table-primary table-hover ">
          <thead>
            {/*  ENCABEZADOS */}
            <tr className="table-secondary">
              <th scope="col">ID</th>
              <th scope="col">Asignado</th>
              <th scope="col">Nombre</th>
              <th scope="col">Telefono</th>
              <th scope="col">Email</th>
              <th scope="col">Fecha de Cotización</th>
              <th scope="col">Canal</th>
              <th scope="col">Fecha de inicio</th>
              <th scope="col">Estilo</th>
              <th scope="col">Proyecto</th>
              <th scope="col">Nº Baños</th>
              <th scope="col">Nº Hab</th>
              <th scope="col">Área</th>
              <th scope="col">Total</th>
              <th scope="col">APU</th>
              <th scope="col">Accion</th>
            </tr>
          </thead>
          <tbody>
            {/*  RENDERIZA CUERPO DE LA TABLA CON LA DATA DE LEADS */}
            {leads.map((lead, index) => {
              return <LeadsRow key={index} lead={lead} />;
            })}
          </tbody>
        </table>
        {loading && <Spinner />}
      </div>
    </div>
  );
};

export default AllLeadsView;
