import React from 'react'

const CodeRow = props => {
    const handleStatusChange = e => {
        e.preventDefault();
        props.service.editDisscountCodeStatus(props.id)
            .then(() => props.updateCodeList())
            .catch(err => console.log(err))
    }
    return (
        <tr>
            <td>{props.id}</td>
            <td>{props.disscount_code}</td>
            <td>{props.is_active ? 'Activo' : 'Inactivo'}</td>
            {
                props.is_active
                    ?
                    <td><button onClick={e => handleStatusChange(e)} className="btn btn-warning">Desactivar</button></td>
                    :
                    <td><button onClick={e => handleStatusChange(e)} className="btn btn-success">Activar</button></td>
            }
            <td>{props.disscount_percentage} %</td>
            <td>{props.times_used}</td>
        </tr>
    )
}

export default CodeRow