import { useEffect, useState } from "react";
import logo from './logo.svg';
import './App.scss';
import DataService from "./components/data-service";
import { Route, Routes } from "react-router-dom";
import LoginArchitect from "./components/ArchitectsView/LoginArchitect";
import { ProtectedRoutes, ArchitectsProtectedRoutes } from "./protectedRoutes/protected-routes";
import MainView from "./components/ArchitectsView/MainView";
import AllRoomsArquitects from "./components/ArchitectsView/UpdatePrices/AllRoomsArchitects";
import { UpdatePricesTable } from "./components/ArchitectsView/UpdatePrices/UpdatePricesTable";
import AllLeadsView from "./components/ArchitectsView/Leads/AllLeadsView";
import AllLeadsRenovView from "./components/ArchitectsView/Leads/AllLeadsRenovView";
import { KnownProjects } from "./components/ArchitectsView/Proyects/KnownProjects";
import WhatsappLeadsScreen from "./components/ArchitectsView/WhatsappLeads/WhatsappLeadsScreen";
import DisscountCodesView from "./components/ArchitectsView/DisscountCodes/DisscountCodesView";

function App() {
  const [user, setUser] = useState(null);
  // const [loggedUser, setLoggedUser] = useState(false);
  useEffect(() => {
    getUser()
    // console.log(user);
  }, []);

  const service = new DataService();
  const getUser = () => {
    if (user === null) {
      service
        .loggedIn()
        .then((response) => {
          // console.log('Response ===>', response);
          setUser(response.user);
          // setLoggedUser(true);
        })
        .catch((error) => {
          // setLoggedUser(false)
          setUser(false);
          console.log(error);
        });
    }
  };

  const updateUser = (userObj) => {
    setUser(userObj);
  };

  return (
    <div className="App">
      <div className="row justify-content-start w-100">

        <Routes>
          <Route path="/" element={<LoginArchitect updateUser={updateUser} />} />
          {user?.username && <>
            <Route path="/main-view" element={<ArchitectsProtectedRoutes theUser={user}> <MainView /> </ArchitectsProtectedRoutes>} />
            <Route path="/update-prices" element={<ArchitectsProtectedRoutes theUser={user}> <AllRoomsArquitects /> </ArchitectsProtectedRoutes>} />
            <Route path="/update-prices/:id" element={<ArchitectsProtectedRoutes theUser={user}><UpdatePricesTable /> </ArchitectsProtectedRoutes>} />
            <Route path="/view-leads" element={<ArchitectsProtectedRoutes theUser={user}>  <AllLeadsView />  </ArchitectsProtectedRoutes>} />
            <Route path="/view-leads-renovadora" element={<ArchitectsProtectedRoutes theUser={user}>  <AllLeadsRenovView />  </ArchitectsProtectedRoutes>} />
            <Route path="/known-projects" element={<ArchitectsProtectedRoutes theUser={user}> <KnownProjects /> </ArchitectsProtectedRoutes>} />
            <Route path="/whatsapp-leads" element={<ArchitectsProtectedRoutes theUser={user}>  <WhatsappLeadsScreen />  </ArchitectsProtectedRoutes>} />
            <Route path="/disscount-codes" element={<ArchitectsProtectedRoutes theUser={user}>  <DisscountCodesView />  </ArchitectsProtectedRoutes>} />
          </>}
        </Routes>

      </div>
    </div>
  );
}

export default App;
