import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAddProject } from "./InputAddProject";
import DataService from "../../../data-service";
import { InputSelectorTypology } from "./InputSelectorTypology";
import { getDecimalsPercentage } from '../consts/changePercentageM2';

export const ModalAddProject = ({ headings, setRequest, request }) => {
  const [newProject, setNewProject] = useState({
    Nombre: "",
    Constructora: "",
    Tipo: "",
    Area: "",
    "N°Baños": "",
    "N°Hab": "",
    Cocina: "",
    Tipologia: "",
    "Sala/Com": "",
    Aseo: "",
    "Hab 1°": "",
    "Baño 1°": "",
    "Baño 2°": "",
    "Hab 2°": "",
    "Hab 3°": "",
    Hall: "",
    Estudio: "",
  });

  const projectArea = newProject.Area || 0;

  // const getDecimalsPercentage = (roomArea) => {
  //   return (Number(roomArea) / Number(projectArea)).toFixed(4);
  // };

  const projectToUpload = {
    ...newProject,
    Cocina: getDecimalsPercentage(newProject.Cocina, projectArea),
    "Sala/Com": getDecimalsPercentage(newProject["Sala/Com"], projectArea),
    Aseo: getDecimalsPercentage(newProject.Aseo, projectArea),
    "Hab 1°": getDecimalsPercentage(newProject["Hab 1°"], projectArea),
    "Baño 1°": getDecimalsPercentage(newProject["Baño 1°"], projectArea),
    "Baño 2°": getDecimalsPercentage(newProject["Baño 2°"], projectArea),
    "Hab 2°": getDecimalsPercentage(newProject["Hab 2°"], projectArea),
    "Hab 3°": getDecimalsPercentage(newProject["Hab 3°"], projectArea),
    Hall: getDecimalsPercentage(newProject.Hall, projectArea),
    Estudio: getDecimalsPercentage(newProject.Estudio, projectArea)
  };

  const dataService = new DataService();

  const handleAddProject = () => {
    if (
      Object.entries(newProject)
        .flat()
        .some((e) => e === "")
    ) {
      return alert("Todos los campos son obligatorios");
    }
    console.log({ projectToUpload });
    dataService
      .addProject(projectToUpload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        alert("Proyecto agregado a la base de datos correctamente");
        setRequest(!request);
      });
  };

  return (
    <>
      <button
        className="btn btn-lg btn-secondary d-flex align-items-center m-3 hoverBtn"
        data-bs-toggle="modal"
        data-bs-target={`#modalNewProject`}
      >
        <FontAwesomeIcon icon={faPlus} className="mx-1" /> Agregar Proyecto
      </button>
      <div
        className="modal fade"
        id={`modalNewProject`}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-secondary">
              <h4 className="text-primary">Agrega un Proyecto</h4>
            </div>
            <div className="modal-body">
              <div className="table-responsive w-100">
                <table className="table table-primary table-hover ">
                  <thead>
                    <tr>
                      {headings.map((heading) => {
                        if (heading === "Edición" || heading === "ID") {
                          return;
                        }
                        return <th key={heading}>{heading}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {headings.map((heading) => {
                        if (heading === "Edición" || heading === "ID") {
                          return;
                        }
                        if (heading === "Tipologia") {
                          return (
                            <InputSelectorTypology
                              setNewProject={setNewProject}
                              newProject={newProject}
                              key={heading}
                              />)
                        }
                        return (
                          <InputAddProject
                            setNewProject={setNewProject}
                            newProject={newProject}
                            key={heading}
                            heading={heading}
                          />
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary hoverBtn"
                data-bs-dismiss="modal"
                onClick={handleAddProject}
              >
                Crear
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
