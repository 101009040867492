import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoutes = ({ children, theUser, ...rest }) => {
    return theUser !== null && theUser.nombre ? children : <Navigate to="/welcome" />;
};

export const ArchitectsProtectedRoutes = ({ children, theUser, ...rest }) => {
    console.log('Protected Route ==>', theUser);
    return theUser && theUser.privilegios ? children : <Navigate to="/" />;
}