import React from 'react'

export const InputSelectorEditTypology = ({ value, name, setEditProject, editProject, readOnly, }) => {

    const handleEdit = (e) => {
        setEditProject({
            ...editProject,
            [e.target.name]: e.target.value,
        });
    };

    const options = [
        { tipo: "A", value: 1 },
        { tipo: "AA", value: 2 },
        { tipo: "B", value: 3 },
        { tipo: "BB", value: 4 },
        { tipo: "C", value: 5 },
        { tipo: "CC", value: 6 }
    ]


    return (
        <select className="form-select w-75" aria-label="Default select example" onChange={handleEdit} name={"tipologia"} >
            {options.map((option, index) => <option key={index} value={option.value}> {option.tipo} </option>)}
        </select>

    );
}
