import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataService from "../../data-service";
import estudio from "../../../images/estudio.png";
import { Spinner } from "../../UI/Spinner";
import "../../../scss/_custom.scss";

export default function AllRoomsArquitects() {
  const [totalRooms, setTotalRooms] = useState();
  const [loading, setLoading] = useState(true);
  const dataService = new DataService();

  useEffect(() => {
    getArchitectsRooms();
  }, []);

  const getArchitectsRooms = () => {
    setLoading(true);
    dataService
      .architectsRooms()
      .then((rooms) => {
        setTotalRooms(rooms);
        console.log(rooms);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <div>
        <h1 className="heading fs-1 text-center mt-4">
          Selecciona el espacio que quieres editar
        </h1>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="row mx-3">
          {totalRooms &&
            totalRooms.map((rooms) => {
              return (
                <div
                  className="col-12 col-md-6 col-lg-4 d-flex flex-column g-4 align-items-center mt-2"
                  key={rooms.id}
                >
                  <Link to={`/update-prices/${rooms.id}`}>
                    <img
                      src={rooms.img_boho || estudio}
                      alt="img"
                      width="300px"
                      height="300px"
                      className=" mt-3 border border-4 border-primary rounded "
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                  <Link
                    to={`/update-prices/${rooms.id}`}
                    type="button"
                    className="btn btn-primary btn-lg shadow w-50 m-1 hoverBtn"
                  >
                    {rooms.nombre}
                  </Link>
                </div>
              );
            })}
        </div>
      )}

      <div className="w-100 d-flex justify-content-center p-3">
        <Link to={"/main-view"} type="button" className="btn btn-light btn-lg">
          Volver
        </Link>
      </div>
    </div>
  );
}
