import React from 'react'
import CodeRow from './CodeRow'

const AllCodesTable = props => {
    const activeCodes = props.allCodes.filter(code => code.is_active).sort((a, b) => a.id - b.id);
    const inactiveCodes = props.allCodes.filter(code => !code.is_active).sort((a, b) => a.id - b.id);
    return (
        <div className='col-md-6 d-flex flex-column align-items-center mt-4'>
            <h2 style={{ color: "white", fontSize: "40px", textShadow: "#EBC991 3px 5px 5px", marginLeft: "10px" }}>
                Current codes
            </h2>
            <div className='w-100 p-3 table-responsive'>
                <table className='table table-secondary table-hover table-striped'>
                    <thead>
                        <tr>
                            <th className='col'>#</th>
                            <th className='col'>Code</th>
                            <th className='col'>Status</th>
                            <th className='col'>Action</th>
                            <th className='col'>Disscount</th>
                            <th className='col'>Usage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeCodes.map(code => <CodeRow {...code} updateCodeList={props.updateCodeList} service={props.service} />)}
                        {inactiveCodes.map(code => <CodeRow {...code} updateCodeList={props.updateCodeList} service={props.service} />)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AllCodesTable