import React, { useState } from 'react'
import DataService from '../../data-service';

const CreateCodeForm = props => {
    const [codeObj, setCodeObj] = useState({
        code: '',
        disscountPercentage: 5
    });
    console.log('codeObj', codeObj);
    const service = new DataService();

    const handleInputChange = e => {
        e.preventDefault();
        setCodeObj({ ...codeObj, [e.target.name]: e.target.value });
    };

    const handleSubmitForm = e => {
        e.preventDefault();
        service.createDisscountCode(codeObj)
            .then(() => {
                props.updateCodeList()
                setCodeObj({...codeObj, code: '', disscountPercentage: 5})
            })
            .catch(err => console.log(err));
    }
    return (
        <div className='col-md-6 d-flex flex-column align-items-center mt-4'>
            <h2 style={{ color: "white", fontSize: "40px", textShadow: "#EBC991 3px 5px 5px", marginLeft: "10px" }}>
                Crear código nuevo
            </h2>
            <form onSubmit={handleSubmitForm}  className='row bg-primary p-3 m-2 rounded-4'>
                <div className="mb-3 row justify-content-evenly">
                    <label for='code' className='col-4 col-form-label'>Código</label>
                    <div className='col-4 w-50'>
                        <input className='form-control' type='text' name='code' value={codeObj.code} onChange={e => handleInputChange(e)}></input>
                    </div>
                </div>
                <div className="mb-3 row justify-content-evenly">
                    <label for='code' className='col-4 col-form-label'>Descuento a aplicar</label>
                    <div className='col-4 w-50 bg-secondary input-group '>
                        <input className='form-control ' type='tel' name='disscountPercentage' value={codeObj.disscountPercentage} onChange={e => handleInputChange(e)}></input>
                        <span className="input-group-text">%</span>
                    </div>
                </div>
                <div className='col-12 text-center'>
                    <button className="btn btn-success" type="submit">Create Code</button>
                </div>
            </form>
        </div>
    )
}

export default CreateCodeForm