import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DataServiceRenov from "../../data-service-renov";
import { Spinner } from "../../UI/Spinner";
import { LeadsRowRenov } from "./LeadsRowRenov";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMugHot } from "@fortawesome/free-solid-svg-icons";

const AllLeadsRenovView = () => {
  const [leadsRenov, setLeadsRenov] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const dataService = new DataServiceRenov();

  useEffect(() => {
    setLoading(true);
    dataService
      .getLeadsRenovadora()
      .then((data) => {
        setLeadsRenov(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  leadsRenov.sort(function (a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  });

  return (
    <div
      className="col-12 d-flex flex-column justify-content-around align-items-center p-3"
      style={{ minHeight: "600px" }}
    >
      <div className="d-flex d-flex justify-content-around align-items-center">
        <FontAwesomeIcon icon={faMugHot} size={"3x"} />
        <h2
          style={{
            color: "white",
            fontSize: "60px",
            textShadow: "#EBC991 3px 5px 5px",
            marginLeft: "10px"
          }}
        >
          Hot Leads
        </h2>
      </div>

      <button
        className="btn btn-lg btn-secondary d-flex align-self-start m-3 hoverBtn"
        onClick={() => history("/main-view")}
      >
        Volver
      </button>
      <div className="table-responsive w-100">
        <table className="table table-primary table-hover ">
          <thead>
            {/*  ENCABEZADOS */}
            <tr className="table-secondary">
              <th scope="col">id</th>
              {/* <th scope="col">checked</th> */}
              <th scope="col">name</th>
              <th scope="col">email</th>
              <th scope="col">phone</th>
              <th scope="col">type</th>
              <th scope="col">customer</th>
              <th scope="col">area</th>
              <th scope="col">direccion</th>
              <th scope="col">precio remodelacion</th>
              <th scope="col">cantidad de baños</th>
              <th scope="col">cantidad de habitacion</th>
            </tr>
          </thead>
          <tbody>
            {/*  RENDERIZA CUERPO DE LA TABLA CON LA DATA DE LEADS */}
            {leadsRenov.map((lead, index) => {
              return <LeadsRowRenov key={index} lead={lead} />;
            })}
          </tbody>
        </table>
        {loading && <Spinner />}
      </div>
    </div>
  );
};

export default AllLeadsRenovView;
