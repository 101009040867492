import axios from "axios";

class DataService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      withCredentials: true,
    });
    this.service = service;
  }

  login = (name, email, cellphone) => {
    return this.service
      .post("/login", { name, email, cellphone })
      .then((response) => response.data);
  };

  arcLogin = (usuario) => {
    return this.service
      .post("/arc-login", usuario)
      .then((response) => response.data);
  };

  reCaptcha = () => {
    return this.service.post("/").then((response) => response.data);
  };

  getRoom = (roomName, area) => {
    return this.service
      .get(`/${roomName}`, { area })
      .then((response) => response.data);
  };

  getAllRooms = (numBaños, numHabitaciones, area, id, isProyect) => {
    return this.service
      .get(`/items/${numBaños}/${numHabitaciones}/${area}/${id}/${isProyect}`)
      .then((response) => response.data);
  };

  architectsRooms = () => {
    return this.service.get("/arquitectos").then((response) => response.data);
  };

  architects = (id) => {
    return this.service
      .get(`/arquitectos/${id}`)
      .then((response) => response.data);
  };

  updatePrices = (id, costo) => {
    return this.service
      .post("/update-prices", { id, costo })
      .then((response) => response.data);
  };

  getLeads = () => {
    return this.service.get("/leads").then((response) => response.data);
  };

  postLeads = (lead) => {
    return this.service
      .post("/post-lead", { lead })
      .then((response) => response.data);
  };

  updateAssignment = (id, assignment) => {
    return this.service
      .put(`/update-assignment/${id}`, { assignment })
      .then((response) => response.data);
  };

  getOpciones = () => {
    return this.service.get("/opciones").then((response) => response.data);
  };

  exportLead = (lead) => {
    return this.service
      .post("/export-lead", { lead })
      .then((response) => response.data);
  };

  exportApus = (lead) => {
    return this.service
      .post("/export-apus", { lead })
      .then((response) => response.data);
  };
  getWhatsappLeads = () => {
    return this.service.get("/whatsapp-leads").then((response) => response.data);
  };
  getWpLeadRemodelacion = (id) => {

    return this.service.get(`/wp-lead-remodelacion/${id}`).then((response) => response.data)
  }
  getWpLeadAdecuacion = (id) => {

    return this.service.get(`/wp-lead-adecuacion/${id}`).then((response) => response.data)
  }

  getKnownProjects = () => {
    return this.service
      .get("/known-projects")
      .then((response) => response.data);
  };

  editProject = (id, editProject) => {
    return this.service
      .put(`/edit-project/${id}`, { editProject })
      .then((response) => response.data);
  };

  addProject = (addProject) => {
    return this.service
      .post("/add-project", { addProject })
      .then((response) => response.data);
  };

  deleteProject = (id) => {
    return this.service
      .delete(`/delete-project/${id}`)
      .then((response) => response.data);
  };

  loggedIn = () => {
    return this.service.get("/loggedIn").then((response) => response.data);
  };

  logout = () => {
    return this.service.post("/logout").then((response) => response.data);
  };

  getAllDisscountCodes = () => {
    return this.service.get('/get-all-codes').then(response => response.data)
  }

  createDisscountCode = (codeObj) => {
    return this.service.post('/create-disscount-code', codeObj).then(response => response.data)
  }

  editDisscountCodeStatus = id => {
    return this.service.put(`/update-code-status/${id}`).then(response => response.data)
  }

  getAllSuperApus = () => {
    return this.service.get('/get-all-superApus').then(response => response.data)
  }

  linkApuToProject = apuInfo => {
    return this.service.post(`/link-superapu`, apuInfo).then(response => response.data)
  }
}

export default DataService;