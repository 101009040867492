import { useState } from 'react';
import DataService from '../../../data-service';

export const CheckEditProject = ({ superapu, project_id, updateProjectsInfo, savingData, setSavingData }) => {
    const service = new DataService()

    const [superApuInfo, setSuperApuInfo] = useState({
        ...superapu,
        superapu_ml: 0,
        project_id: project_id
    });
    

    const handleSuperapuMl = e => {
        e.preventDefault();
        setSuperApuInfo({
            ...superApuInfo,
            [e.target.name]: Number(e.target.value)
        });
    };

    const handleOnClick = e => {
        if (superApuInfo.superapu_ml < 1) return;
        e.preventDefault();
        setSavingData(true);
        service
            .linkApuToProject(superApuInfo)
            .then(data => {
                updateProjectsInfo()
                setSavingData(false);
            })
            .catch(error => console.log(error.errorMessage))
            
    };


    return (
        <div className="d-flex justify-content-start my-1">
            <input name={"superapu_ml"} onChange={handleSuperapuMl} value={superApuInfo.superapu_ml} type='number' className="col-1 mx-2" min={0} />
            <label className="mx-2" >{superApuInfo.apu_name}</label>
            <button className='btn btn-primary btn-sm' onClick={handleOnClick}>
                {
                    savingData ?
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </>
                        :
                        'Guardar'
            }
            </button>
        </div>
    )
}
