import React, { useState } from "react";
import DataServiceRenov from "../../data-service";
import { ModalApusLeads } from "./ModalApusLeads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faBoxesPacking,
} from "@fortawesome/free-solid-svg-icons";

export const LeadsRowRenov = ({ lead }) => {
  const [checked, setChecked] = useState(lead?.asignado || false);
  const [loading, setLoading] = useState(false);
  const sheetsWindow = null;

  // const handleCheck = () => {
  //   setLoading(true);
  //   setChecked(!checked);
  //   dataService.updateAssignment(lead.id, !checked).then(() => {
  //     setLoading(false);
  //   });
  // };

  // const handleLeadClick = () => {
  //   dataService.exportLead(lead);

  //   if (sheetsWindow === null || sheetsWindow.closed) {
  //     window.open(
  //       "https://docs.google.com/spreadsheets/d/1_xwwC76SXia4_1oR0LQwAEdxfOBKdrOKHbH5Y_ZotMM/edit#gid=1255670085",
  //       sheetsWindow
  //     );
  //   } else {
  //     sheetsWindow.focus();
  //   }
  // };

  // const handleApuClick = () => {
  //   dataService.exportApus(lead);

  //   if (sheetsWindow === null || sheetsWindow.closed) {
  //     window.open(
  //       "https://docs.google.com/spreadsheets/d/1_xwwC76SXia4_1oR0LQwAEdxfOBKdrOKHbH5Y_ZotMM/edit#gid=1613660502",
  //       sheetsWindow
  //     );
  //   } else {
  //     sheetsWindow.focus();
  //   }
  // };

  return (
    <tr>
      <th scope="row">{lead.id}</th>
      {/* <td>
        <div className="form-check form-switch d-flex justify-content-center align-items-center">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            disabled={loading}
            onChange={handleCheck}
            id={lead.id}
            checked={checked}
          />
        </div>
      </td> */}
      <td>{lead?.nombre}</td>
      <td>{lead?.correo}</td>
      <td>{lead?.telefono}</td>
      <td>{lead?.type}</td>
      <td>{lead?.customer}</td>
      <td>{lead?.area}</td>
      <td>{lead?.direccion}</td>
      <td>{lead?.precio_remo}</td>
      <td>{lead?.cant_baños}</td>
      <td>{lead?.cant_habitaciones}</td>
    </tr>
  );
};

export default LeadsRowRenov
