import React from "react";

export const InputEditProject = ({
  value,
  name,
  setEditProject,
  editProject,
  readOnly,
}) => {
  const handleEdit = (e) => {
    setEditProject({
      ...editProject,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <input
      type="text"
      name={name}
      readOnly={readOnly}
      className="form-control w-75"
      style={{ width: "100px" }}
      value={value}
      onChange={handleEdit}
    />
  );
};
